import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import { Input, Button, Loading, Grid, Select } from '@geist-ui/react'



export default function AddFighter (props) {

  const [loading, setLoading] = useState(false)
  const [title, setTitle] = useState()
  const [sport, setSport] = useState(props.sport && props.sport !== 'all' ? props.sport : '')
  const [fighterone, setFighterOne] = useState(props.fighter && props.fighter)
  const [fightertwo, setFighterTwo] = useState()
  const [complete, setComplete] = useState(0)
  const [fighters, setFighters] = useState([])
  const [sports, setSports] = useState([])

  useEffect(() => {
        fetch(`/.netlify/functions/fauna-all/`, {
            body: JSON.stringify({
              index: 'fighters_all'
            }),
          method: 'POST'}).then((response) => {
            return response.json()}).then((response) => (setFighters(response.data || []), setLoading()))
         .catch(error => setLoading());
  }, [])

  useEffect(() => {
        fetch(`/.netlify/functions/fauna-all/`, {
            body: JSON.stringify({
              index: 'sports_all'
            }),
          method: 'POST'}).then((response) => {
            return response.json()}).then((response) => (setSports(response.data || []), setLoading()))
         .catch(error => setLoading());
  }, [])
  

 function submitinfo () {
          fetch(`/.netlify/functions/fauna-find/`, {
            body: JSON.stringify({
              index: 'fights_by_fighters',
              id: [fighterone.split("-")[0], fightertwo.split("-")[0], sport]
            }),
          method: 'POST'}).then((response) => {
            return response.json()}).then((response) => {
              if(response.data && response.data.length) {
                navigate('/fight/' + fighterone.split("-")[1].toLowerCase().split(" ").join("-") + "-vs-" + fightertwo.split("-")[1].toLowerCase().split(" ").join("-"));
              }
              else {
                fetch(`/.netlify/functions/fauna-find/`, {
                  body: JSON.stringify({
                    index: 'fights_by_fighters',
                    id: [fightertwo.split("-")[0], fighterone.split("-")[0], sport]
                  }),
                method: 'POST'}).then((response) => {
                  return response.json()}).then((response) => {
                    if(response.data && response.data.length) {
                      navigate('/fight/' + 
                        fightertwo.split("-")[1].toLowerCase().split(" ").join("-") + "-vs-" + 
                        fighterone.split("-")[1].toLowerCase().split(" ").join("-") + "-" +
                        sport.toLowerCase().split(" ").join("-"));
                    }
                    else {
                      fetch(`/.netlify/functions/fauna-create/fights`, {
                        body: JSON.stringify({
                          title: fighterone.split("-")[1] + ' vs ' + fightertwo.split("-")[1],
                          sport: sport,
                          fighterone: fighterone.split("-")[0],
                          fightertwo: fightertwo.split("-")[0],
                        }),
                        method: 'POST'
                      }).then(response => {
                        return response.json()}).then(response => {
                          setLoading(false);
                          navigate('/fight?fight=' + response.ref['@ref'].id);
                          fetch(`https://api.netlify.com/build_hooks/64e6357e371f9c0aff1227c8`, {
                            method: 'POST'}).then((response) => {
                            return response.json()}).then((response) => {
                              console.log(response);
                            })
                          .catch(error => console.log(error));
                        })
                        .catch(error => (setLoading(false), console.log(error)));
                    }
                  })
               .catch(error => (setLoading(false), alert('There has been an error. Please try again.')));
              }
            })
         .catch(error => (setLoading(false), alert('There has been an error. Please try again.')));
  }

 const sportHandler = val => setSport(val)

 return (
    <>
        {complete < 1 && !loading && <div className="fighter-add">
              <h6 style={{margin:'30px 0 20px 0'}}>Add ➕ Fight</h6>
              <Grid.Container gap={1.2}>
               <Grid xs={24} md={12}>
               <Select scale={1.5} width="100%" placeholder="Select Fighter" value={fighterone} onChange={(val) => setFighterOne(val)}>
                  {fighters.filter((fighter) => fightertwo !== fighter[11]['@ref'].id + "-" + fighter[0]).map((fighter) => (
                    <Select.Option className="fighter-select" value={fighter[11]['@ref'].id + "-" + fighter[0]}><img src={fighter[1]} width="35px" style={{objectFit:'cover', objectPosition: 'center top', height:'35px'}} /> {fighter[0]}</Select.Option>
                   ))}
                </Select>
                </Grid>
                <Grid xs={24} md={12}>
                <Select scale={1.5} width="100%" placeholder="Select Opponent" value={fightertwo} onChange={(val) => setFighterTwo(val)}>
                  {fighters.filter((fighter) => fighterone !== fighter[11]['@ref'].id + "-" + fighter[0]).map((fighter) => (
                    <Select.Option className="fighter-select" value={fighter[11]['@ref'].id + "-" + fighter[0]}><img src={fighter[1]} width="35px" style={{objectFit:'cover', objectPosition: 'center top', height:'35px'}} /> {fighter[0]}</Select.Option>
                   ))}
                </Select>
                </Grid>
                <Grid xs={24}>
                <Select scale={1.5} width="100%" placeholder="Select Sport" value={sport} onChange={sportHandler}>
                  {sports.map((sport) => (
                    <Select.Option value={sport[0]}><span className="sports-font">{sport[1]}</span> {sport[0]}</Select.Option>
                   ))}
                </Select>
                </Grid>
                <Grid xs={24}>
                 <Button scale={1.2} width="100%" type="secondary" loading={loading} disabled={!fighterone || !fightertwo || !sport}
                   onClick={() => submitinfo()} shadow>
                    {props.update ? 'Update' : 'Add'} Fight
                 </Button>
                </Grid>
               </Grid.Container>
              </div>}
              {complete > 0 && !loading && <div className="fighter-add">
                <h4>Success!</h4>
                <Button type="secondary" onClick={() => (setFighterOne(props.fighter && props.fighter), setFighterTwo(), setComplete(0), props.onChange())} shadow>Close</Button>
              </div>}
              {loading && <Loading />}
   </>)
}
