import React from "react"
import { navigate } from 'gatsby'
import Helmet from 'react-helmet'
import AddFight from '../components/AddFight'

export default function Fight ()  {

  return (
    <>
      <Helmet>
        <title>Add Fight - Fanpowered</title>
        <meta name="robots" content="noindex" data-react-helmet="true" />
      </Helmet>
        <AddFight onChange={() => navigate('/fights/')} />
      </>
  )
}